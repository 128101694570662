//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    transform: translate(0, -25%);
    transition: transform 0.3s ease-out;
  }
  &.in .modal-dialog {
    transform: translate(0, 0);
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: @modal-content-bg;
  background-clip: padding-box;
  border: 1px solid @modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid @modal-content-border-color;
  border-radius: @border-radius-large;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal-background;
  background-color: @modal-backdrop-bg;
  // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.in {
    opacity: @modal-backdrop-opacity;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: @modal-title-padding;
  border-bottom: 1px solid @modal-header-border-color;
  &:extend(.clearfix all);
}
// Close icon
.modal-header .close {
  margin-top: -2px;
  outline: none;
}

// Title text within header
.modal-title {
  margin: 0;
  line-height: @modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: @modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  padding: @modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid @modal-footer-border-color;
  &:extend(.clearfix all); // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn {
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    margin-left: 5px;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@media (min-width: @screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: @modal-md;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  // Modal sizes
  .modal-sm {
    width: @modal-sm;
  }
}

@media (min-width: @screen-md-min) {
  .modal-lg {
    width: @modal-lg;
  }
}

/**
* Modal
* ============================================================================
*/
.modal-backdrop {
  background-color: @gray-dark;
  // Match zIndex.modal in theme.jsx
  z-index: 10000;
}

.modal {
  // Match zIndex.modal in theme.jsx
  z-index: 10000;
}

.modal-container {
  position: relative;
}
.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute;
}

.modal {
  .close {
    background: none;
    border: 0;
    padding: 0 6px;
    margin: 0;
    font-size: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    z-index: 100;
    color: lighten(@gray, 20);
    transition: color 0.1s linear;

    &:hover {
      color: @gray;
    }

    &.focus-visible {
      outline: none;
      border-radius: 50%;
      background: lighten(@gray, 60);
      color: @gray;
    }
  }
  .modal-content {
    padding: 30px;
    box-shadow: 0 15px 40px 0 rgba(67, 62, 75, 0.3), 0 1px 15px 0 rgba(67, 61, 74, 0.15);
    border-radius: 7px;
    border: 0;
  }

  .modal-header {
    border-bottom: 1px solid @trim;
    border-radius: 7px 7px 0 0;
    padding: 20px 30px;
    margin: -30px -30px 20px;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 1.1;
    }
  }

  .modal-body {
    padding: 0;
    font-size: 15px;

    p:last-child {
      margin-bottom: 0;
    }

    .alert {
      border-radius: @border-radius-base;
    }

    img {
      max-width: 100%;
    }

    .form-actions {
      margin-bottom: 0;
    }
  }

  .modal-footer {
    padding: 20px 30px;
    border-top: 1px solid @trim;
    margin: 20px -30px -30px;
    display: flex;
    justify-content: flex-end;
    text-align: initial;
  }
}
